.notifications {
  width: 20%;
  padding: 10px;
  color: #fff;
  background: #241f17;
}
.notifications-header {
  border-bottom: 1px solid rgb(55, 54, 54);
  padding-bottom: 10px;
}

